import { Button, Col, Form, Tabs, Row, Select, Table, Modal } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  deleteGamesQues,
  getGamesQues,
  getKlassesLessons,
  getLessons,
  patchGamesQues,
  postGamesQues
} from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { BsEyeFill } from "react-icons/bs";
import { message } from "antd";
import { useStore } from "../../store/Store";
import dayjs from "dayjs";
import { InputText } from "../../utils/Inputs";

export default function GamesQues() {
  const [form] = Form.useForm();
  const [form_sort] = Form.useForm();
  const [data, setData] = useState(null);
  const [edit, setedit] = useState(null);
  const [klass, setKlass] = useState([]);
  const [klass_id, setklass_id] = useState(null);
  const [changePage, setchangePage] = useState(false);
  const [cookie, _] = useCookies();
  const [selectlesson, setSelectlesson] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [lessons_id, setLessons_id] = useState(null);
  const setLoader = useStore((state) => state.setLoader);

  const editData = (res) => {
    setchangePage(true);

    if (lessons) {
      const selectedLesson = lessons.find((x) => x.id === res.lesson);
      if (selectedLesson) {
        sortKlass(selectedLesson.klass);
        setLessons_id(selectedLesson.id);
        setklass_id(selectedLesson.klass);

        res.lesson = selectedLesson.id;
        res.klass = selectedLesson.klass;
      }
    }

    res.date = dayjs(res.date, "YYYY-MM-DD");
    res.time = dayjs(res.time, "HH:mm");

    form.setFieldsValue(res);
    setedit(res.id);
  };

  const showModal = () => {
    setchangePage(true);
  };

  const sortLesson = (e) => {
    setLessons_id(e);
    getGamesQuesData(e);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      render: (text, res, index) => {
        return index + 1;
      },
    },
    {
      title: "Nomi",
      key: "question",
      dataIndex: "question",
    },
    {
      title: "Matni",
      key: "answer",
      dataIndex: "answer",
    },
    {
      title: "Mavzu",
      key: "lesson",
      dataIndex: "lesson",
      render: (res) => {
        const lesson = lessons.find((x) => x.id === res);
        return lesson ? lesson.title : "-";
      },
    },
    {
      title: "O'zgartirish",
      key: "edit",
      dataIndex: "id",
      render: (res, objc) => {
        return (
          <Button
            onClick={() => {
              editData(objc);
            }}
            type="primary"
          >
            <TbEdit size={"1.3em"} />
          </Button>
        );
      },
    },
    {
      title: "O'chirish",
      key: "delete",
      dataIndex: "id",
      render: (res) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              deleteData(res);
            }}
            danger
          >
            <MdDelete size={"1.3em"} />
          </Button>
        );
      },
    },
  ];

  const getGamesQuesData = (a) => {
    getGamesQues({ lesson_id: a? a===-1?null:a : lessons_id }, cookie.token).then((res) => {
      setData(res.data);
    });
  };

  const deleteData = (id) => {
    setLoader(true);
    deleteGamesQues(cookie.token, id)
      .then(() => {
        message.success("Ma'lumot o'chirildi");
        setLoader(false);
        getGamesQuesData();
      })
      .catch(() => {
        setLoader(false);
        message.error("Ma'lumot o'chirilmadi");
      });
  };

  const getKlassData = () => {
    getKlassesLessons(cookie.token).then((res) => {
      setKlass(res.data);
    });
  };

  useEffect(() => {
    getGamesQuesData();
    getKlassData();
    getLessonsData();
  }, []);

  const onFinish = (event) => {
    setLoader(true);
    event.lesson = lessons_id;

    if (edit === null) {
      postGamesQues(cookie.token, event)
        .then(() => {
          message.success("Ma'lumot saqlandi");
          handleClose();
        })
        .catch(() => {
          setLoader(false);
          message.error("Ma'lumot saqlanmadi");
        });
    } else {
      patchGamesQues(cookie.token, event, edit)
        .then(() => {
          message.success("Ma'lumot o'zgartirildi");
          handleClose();
        })
        .catch(() => {
          setLoader(false);
          message.error("Ma'lumot o'zgartirilmadi");
        });
    }
  };

  const getLessonsData = () => {
    getLessons(cookie.token).then((res) => {
      setLessons(res.data);
    });
  };

  const sortKlass = (klassId) => {
    const filteredLessons = lessons.filter((lesson) => lesson.klass === klassId);
    setSelectlesson(filteredLessons);
    setLessons_id(null)
    form.setFieldsValue({lesson:null})
    form_sort.setFieldsValue({lesson:null})
  };

  const handleClose = () => {
    setLoader(false);
    setchangePage(false);
    setedit(null);
    getGamesQuesData(-1);
    form.resetFields();
    form_sort.resetFields()
    form.setFieldsValue({lesson:null, klass:null})
    form_sort.setFieldsValue({lesson:null, klass:null})
  };

  const Tabitems = [
    {
      key: "1",
      label: `Lug'at qo'shish`,
      children: (
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="admin_btns">
            <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
              Orqaga
            </Button>
            <Button style={{ marginLeft: "20px", backgroundColor: "#00a900" }} type="primary" htmlType="submit">
              Saqlash
            </Button>
          </div>
          <Row style={{ paddingTop: "20px" }}>
            <Col lg={12} md={24}>
              <div style={{ padding: "0px 20px 10px 20px" }}>
                <Form.Item label="Sinf tanlash" name="klass" required={true}>
                  <Select
                    defaultValue={klass_id}
                    style={{ width: "100%" }}
                    placeholder={"Sinf tanlash"}
                    onChange={sortKlass}
                  >
                    {klass.length !== 0 &&
                      klass.map((item, key) => (
                        <Select.Option key={key} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col lg={12} md={24}>
              <div style={{ padding: "0px 20px 10px 20px" }}>
                <Form.Item label="Mavzu tanlash" name="lesson" required={true}>
                  <Select
                    defaultValue={lessons_id}
                    style={{ width: "100%" }}
                    placeholder={"Mavzu tanlash"}
                    onChange={sortLesson}
                  >
                    {selectlesson &&
                      selectlesson.map((item, key) => (
                        <Select.Option key={key} value={item.id}>
                          {item.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
              <InputText label="Atama" name="question" />
            </Col>
            <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
              <InputText label={`Matni`} name="answer" />
            </Col>
          </Row>
        </Form>
      ),
    },
  ];

  return (
    <div>
      <div className="adminBox">
        {!changePage ? (
          <>
            <div className="admin_btns">
              <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                Lug'at qo'shish
              </Button>
              <Form
              className="form_sort"
              style={{display:'flex', height:'100%', alignItems:'center', paddingTop:'20px'}}
          form={form_sort}
          name="basic"
          labelCol={{ span: 12 }}
          wrapperCol={{ span:12 }}
          initialValues={{ remember: true }}
          
          autoComplete="off"
        >
          <Form.Item name="klass" required={false}>
              <Select style={{ width: 150, marginLeft: "20px" }} placeholder={"Sinf tanlash"} onChange={sortKlass}>
                {klass.length !== 0 &&
                  klass.map((item, key) => (
                    <Select.Option key={key} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
              </Form.Item>
              <Form.Item name="lesson" required={false}>
              <Select style={{ width: 150, marginLeft: "20px" }} placeholder={"Mavzu tanlash"} onChange={sortLesson}>
                {selectlesson.length !== 0 &&
                  selectlesson.map((item, key) => (
                    <Select.Option key={key} value={item.id}>
                      {item.title}
                    </Select.Option>
                  ))}
              </Select>
              </Form.Item>
              </Form>
            </div>
            <Table
              className="table_go"
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={data}
              pagination={{ pageSize: 5 }}
            />
          </>
        ) : (
          <Tabs defaultActiveKey="1" items={Tabitems} />
        )}
      </div>
    </div>
  );
}
