import {
  Button,
  Col,
  Image,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  deleteLessons,
  getLessons,
  postLessons,
  putLessons,
  getKlassesLessons,
} from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { UploadOutlined } from "@ant-design/icons";
import { TbEdit } from "react-icons/tb";
import { BsEyeFill } from "react-icons/bs";
import ReactQuill from "react-quill";
import { message, Space, Upload } from "antd";
import { api } from "../../host/Host";
import { useStore } from "../../store/Store";
import table_image from "../../assets/admin/table_image.png";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";

import { beforeUploadLessons, getBase64 } from "../../components/Objects";

export default function Lessons() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [description, setdescription] = useState("");
  const [sel_data, setSel_data] = useState(null);
  const [klasses, setKlasses] = useState([]);
  const [image_id, setimage_id] = useState(null);
  const [file_id, setfile_id] = useState(null);
  const [modal, setModal] = useState(false);
  const [edit, setedit] = useState(null);
  const [changePage, setchangePage] = useState(false);
  const [btnDis, setBtnDis] = useState(false);
  const [cookie, setCookie] = useCookies();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const setLoader = useStore((state) => state.setLoader);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Yuklanyapdi
      </div>
    </div>
  );
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setBtnDis(true);
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setBtnDis(false);
      setimage_id(info.file.originFileObj);
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const editData = (res) => {
    setchangePage(true);
    form.setFieldsValue(res);
    setImageUrl(res.image);
    setedit(res.id);
  };
  const showModal = () => {
    setchangePage(true);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      render: (text, res, index) => {
        return index + 1;
      },
    },
    {
      title: "Nomi",
      key: "title",
      dataIndex: "id",
      render: (id, res) => {
        return <div className="table_btns">{res.title}</div>;
      },
    },
    {
      title: "Ko'rilganlar",
      key: "seens",
      dataIndex: "id",
      render: (id, res) => {
        return <div className="table_btns">{res.seens}</div>;
      },
    },
    {
      title: "Likelar",
      key: "likes",
      dataIndex: "id",
      render: (id, res) => {
        return <div className="table_btns">{res.likes}</div>;
      },
    },
    {
      title: "Sinflar",
      key: "klass_name",
      dataIndex: "klass_name",
     
    },
    // {
    //   title: "Link_3d",
    //   key: "link_3d",
    //   dataIndex: "id",
    //   render: (id, res) => {
    //     return <div className="table_btns">{res.link_3d}</div>;
    //   },
    // },
    {
      title: "Mavzu matni",
      key: "description",
      dataIndex: "id",
      render: (id, res) => {
        return (
          <div className="table_btns">
            <Button
              type="primary"
              onClick={() => {
                setModal(true);
                setSel_data(res);
              }}
            >
              <BsEyeFill size={"1.3em"} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "Rasm",
      key: "image",
      dataIndex: "image",
      render: (res) => {
        return <Image height={"60px"} width={"100px"} src={res ? res : table_image} />;
      },
    },
    {
      title: "O'zgartirish",
      key: "edit",
      dataIndex: "id",
      render: (res, objc) => {
        return (
          <Button
            onClick={() => {
              editData(objc);
            }}
            type="primary"
          >
            <TbEdit size={"1.3em"} />
          </Button>
        );
      },
    },
    {
      title: "O'chirish",
      key: "delete",
      dataIndex: "id",
      render: (res) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              deleteData(res);
            }}
            danger
          >
            <MdDelete size={"1.3em"} />
          </Button>
        );
      },
    },
  ];
  const getLessonsData = (klassdata) => {
    getLessons(cookie.token).then((res) => {
      let newData = res.data;
      for (let i = 0; i < newData.length; i++) {
        newData[i].klassname =
          klassdata && klassdata.filter((item) => item.id === newData[i].klass);
      }

      setData(newData);
      setLoader(false);
    });
  };

  const getKlassesLessonsData = () => {
    getKlassesLessons(cookie.token).then((res) => {
      setKlasses(res.data);
      getLessonsData(res.data);
    });
  };
  const deleteData = (id) => {
    setLoader(true);
    deleteLessons(cookie.token, id)
      .then((res) => {
        message.success("Ma'lumot o'chirildi");
        getKlassesLessonsData();
      })
      .catch((err) => {
        setLoader(false);
        message.error("Ma'lumot o'chirilmadi");
      });
  };
  useEffect(() => {
    // getLessonsData();
    getKlassesLessonsData();
  }, []);

  const onFinish = (event) => {
    setLoader(true);
    event.image = image_id;
    parseInt(event.klass);
    const formData = new FormData();
    formData.append("title", event.title);
    formData.append("description", event.description);
    formData.append("likes", event.likes);
    formData.append("seens", event.seens);
    // formData.append("link_3d", event.link_3d);
    formData.append("klass", event.klass);
    if (edit === null) {
      formData.append("image", event.image);
      postLessons(cookie.token, formData)
        .then((res) => {
          message.success("Ma'lumot saqlandi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot saqlanmadi");
        });
    } else {
      if (image_id) {
        formData.append("image", event.image);
      }
      putLessons(cookie.token, formData, edit)
        .then((res) => {
          message.success("Ma'lumot o'zgartirildi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot o'zgartirilmadi");
        });
    }
  };
  const handleClose = () => {
    setfile_id(null);
    setImageUrl();
    setchangePage(false);
    getKlassesLessonsData();
    setedit(null);
    form.resetFields();
  };
  return (
    <div>
      <div className="adminBox">
        {!changePage ? (
          <>
            <div className="admin_btns">
              <Button
                style={{ marginBottom: "20px" }}
                onClick={showModal}
                type="primary"
              >
                Mavzu qo'shish
              </Button>
            </div>

            <Table
              rowKey={(res) => res.id}
              dataSource={data}
              columns={columns}
            />
          </>
        ) : (
          <>
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="admin_btns">
                <Button
                  style={{ marginBottom: "20px" }}
                  onClick={handleClose}
                  type="primary"
                >
                  Orqaga
                </Button>
                <Button
                  disabled={btnDis}
                  style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                  type="primary"
                  htmlType="submit"
                >
                  {" "}
                  Saqlash
                </Button>
              </div>
              <Row style={{ paddingTop: "20px" }}>
                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                  <Form.Item
                    label="Nomi"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Bu maydonni to'ldirish shart",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Ko'rilganlar"
                    name="seens"
                    rules={[
                      {
                        required: true,
                        message: "Bu maydonni to'ldirish shart",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    label="Likelar"
                    name="likes"
                    rules={[
                      {
                        required: true,
                        message: "Bu maydonni to'ldirish shart",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>

                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                  <Form.Item
                    label="Rasm"
                    name="image"
                    rules={[
                      {
                        required: true,
                        message: "Bu maydonni to'ldirish shart",
                      },
                    ]}
                  >
                    <Upload
                      name="image"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      customRequest={({ file, onSuccess }) => {
                        setTimeout(() => {
                          onSuccess("ok");
                        }, 0);
                      }}
                      beforeUpload={beforeUploadLessons}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "70px",
                            height: "70px",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </Form.Item>
                  <br />
                  <Form.Item
                    label="Sinf tanlash"
                    name="klass"
                    rules={[
                      {
                        required: true,
                        message: "Bu maydonni to'ldirish shart",
                      },
                    ]}
                  >
                    {/* <Input type="number" /> */}
                    <Select
                      style={{ width: 220 }}
                      placeholder="Sinfni tanlash"
                      required
                    >
                      {klasses &&
                        klasses.length !== 0 &&
                        klasses.map((item, key) => {
                          return (
                            <option key={key} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  {/* <Form.Item
                    label="Link_3d"
                    name="link_3d"
                    rules={[
                      {
                        required: true,
                        message: "Bu maydonni to'ldirish shart",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item> */}
                </Col>
              </Row>
              <div style={{ padding: "15px 20px 60px 20px" }}>
                <Form.Item
                  label="Mavzu matni"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Bu maydonni to'ldirish shart",
                    },
                  ]}
                >
                  <ReactQuill
                    style={{ height: "400px", fontSize: "16px" }}
                    theme="snow"
                    value={description}
                    onChange={setdescription}
                  />
                </Form.Item>
              </div>
            </Form>
          </>
        )}
      </div>
      <Modal
        title=""
        footer={false}
        open={modal}
        onOk={() => {
          setModal(false);
        }}
        onCancel={() => {
          setModal(false);
        }}
      >
        <div>
          <b style={{ fontSize: "18px" }}>Izohlar</b>
          <p
            style={{ fontSize: "16px" }}
            dangerouslySetInnerHTML={{
              __html: sel_data !== null ? sel_data.description : "",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
