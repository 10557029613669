import axios from "axios";
import { api } from "./Host";

export const loginRequest = (username, password) => {
    return axios.post(`${api}api/token/`, { password, username });
};
export const getTeachers = (token) => {
    return axios.get(`${api}/api/teachers/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getNews = (token) => {
    return axios.get(`${api}api/news/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const postNews = (token, data) => {
    return axios.post(`${api}api/news/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const patchNews = (token, data, id) => {
    return axios.patch(`${api}api/news/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const deleteNews = (token, id) => {
    return axios.delete(`${api}api/news/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getVideos = (data, token) => {
    return axios.get(`${api}api/videos/`, {
        params:data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const postVideos = (token, data) => {
    return axios.post(`${api}api/videos/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const patchVideos = (token, data, id) => {
    return axios.patch(`${api}api/videos/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const deleteVideos = (token, id) => {
    return axios.delete(`${api}api/videos/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getLessons = (token) => {
    return axios.get(`${api}api/lessons/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getKlassesLessons = (token) => {
    return axios.get(`${api}api/klasses/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const postLessons = (token, data) => {
    return axios.post(`${api}api/lessons/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const putLessons = (token, data, id) => {
    return axios.patch(`${api}api/lessons/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const deleteLessons = (token, id) => {
    return axios.delete(`${api}api/lessons/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getUsers = (token) => {
    return axios.get(`${api}api/staff/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postUsers = (token, data) => {
    return axios.post(`${api}api/staff/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const putUsers = (token, data, id) => {
    return axios.patch(`${api}api/staff/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const deleteUsers = (token, id) => {
    return axios.delete(`${api}api/staff/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getArticles = (token) => {
    return axios.get(`${api}api/articles/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const postArticles = (token, data) => {
    return axios.post(`${api}api/articles/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const putArticles = (token, data, id) => {
    return axios.put(`${api}api/articles/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteArticles = (token, id) => {
    return axios.delete(`${api}api/articles/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getSlider = (token) => {
    return axios.get(`${api}/api/sliders/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postSlider = (token, data) => {
    return axios.post(`${api}/api/sliders/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};
export const putSlider = (token, data, id) => {
    return axios.put(`${api}/api/sliders/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const deleteSlider = (token, id) => {
    return axios.delete(`${api}/api/sliders/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getAbout = (token) => {
    return axios.get(`${api}/api/abouts/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postAbout = (token, data) => {
    return axios.post(`${api}/api/abouts/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};
export const putAbout = (token, data, id) => {
    return axios.put(`${api}/api/abouts/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const deleteAbout = (token, id) => {
    return axios.delete(`${api}/api/abouts/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getTeacher = (token) => {
    return axios.get(`${api}/api/teachers/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postTeacher = (token, data) => {
    return axios.post(`${api}/api/teachers/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};
export const putTeacher = (token, data, id) => {
    return axios.put(`${api}/api/teachers/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const deleteTeacher = (token, id) => {
    return axios.delete(`${api}/api/teachers/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getCategory = (token) => {
    return axios.get(`${api}/api/categories/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postCategory = (token, data) => {
    return axios.post(`${api}/api/categories/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};
export const putCategory = (token, data, id) => {
    return axios.put(`${api}/api/categories/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const deleteCategory = (token, id) => {
    return axios.delete(`${api}/api/categories/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getSubCategory = (token) => {
    return axios.get(`${api}/api/sub_categories/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postSubCategory = (token, data) => {
    return axios.post(`${api}/api/sub_categories/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};
export const putSubCategory = (token, data, id) => {
    return axios.put(`${api}/api/sub_categories/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const deleteSubCategory = (token, id) => {
    return axios.delete(`${api}/api/sub_categories/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getCourse = (token) => {
    return axios.get(`${api}/api/courses/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postCourse = (token, data) => {
    console.log(data);
    return axios.post(`${api}/api/courses/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const putCourse = (token, data, id) => {
    return axios.put(`${api}/api/courses/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const deleteCourse = (token, id) => {
    return axios.delete(`${api}/api/courses/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getFaq = (token) => {
    return axios.get(`${api}/api/faqs/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postFaq = (token, data) => {
    return axios.post(`${api}/api/faqs/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};
export const putFaq = (token, data, id) => {
    return axios.put(`${api}/api/faqs/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const deleteFaq = (token, id) => {
    return axios.delete(`${api}/api/faqs/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getDes = (token) => {
    return axios.get(`${api}/api/course_descriptions/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const postDes = (token, data) => {
    return axios.post(`${api}/api/course_descriptions/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const putDes = (token, data, id) => {
    return axios.put(`${api}/api/course_descriptions/${id}/`, data, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const delDes = (token, id) => {
    return axios.delete(`${api}/api/course_descriptions/${id}/`, {
        headers: {
            Authorization: `Token ${token}`,
        },
    });
};

export const getEvents = (token) => {
    return axios.get(`${api}api/events/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postEvents = (token, data) => {
    return axios.post(`${api}api/events/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchEvents = (token, data, id) => {
    return axios.patch(`${api}api/events/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteEvents = (token, id) => {
    return axios.delete(`${api}api/events/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getRegions = (token) => {
    return axios.get(`${api}api/regions/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getFormReq = (token) => {
    return axios.get(`${api}api/form-requests/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postFormReq = (token, data) => {
    return axios.post(`${api}api/form-requests/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchFormReq = (token, data, id) => {
    return axios.patch(`${api}api/form-requests/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteFormReq = (token, id) => {
    return axios.delete(`${api}api/form-requests/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getLinks = (token) => {
    return axios.get(`${api}api/links/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postLinks = (token, data) => {
    return axios.post(`${api}api/links/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchLinks = (token, data, id) => {
    return axios.patch(`${api}api/links/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteLinks = (token, id) => {
    return axios.delete(`${api}api/links/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getAudio = (token) => {
    return axios.get(`${api}api/audios/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postAudio = (token, data) => {
    return axios.post(`${api}api/audios/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchAudio = (token, data, id) => {
    return axios.patch(`${api}api/audios/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteAudio = (token, id) => {
    return axios.delete(`${api}api/audios/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getDocs = (token) => {
    return axios.get(`${api}api/docs/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postDocs = (token, data) => {
    return axios.post(`${api}api/docs/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchDocs = (token, data, id) => {
    return axios.patch(`${api}api/docs/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteDocs = (token, id) => {
    return axios.delete(`${api}api/docs/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getPresentations = (token) => {
    return axios.get(`${api}api/presentations/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postPresentations = (token, data) => {
    return axios.post(`${api}api/presentations/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchPresentations = (token, data, id) => {
    return axios.patch(`${api}api/presentations/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deletePresentations = (token, id) => {
    return axios.delete(`${api}api/presentations/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getSiteinfo = (token) => {
    return axios.get(`${api}api/site-info`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const patchSiteinfo = (token, data, id) => {
    return axios.patch(`${api}api/site-info/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const getDictionaries = (token) => {
    return axios.get(`${api}api/dictionaries/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postDictionaries = (token, data) => {
    return axios.post(`${api}api/dictionaries/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const postDictionariesUpload = (token, data) => {
    return axios.post(`${api}api/dictionaries/file-upload/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const patchDictionaries = (token, data, id) => {
    return axios.patch(`${api}api/dictionaries/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteDictionaries = (token, id) => {
    return axios.delete(`${api}api/dictionaries/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getGamesQues = (data, token) => {
    return axios.get(`${api}api/lessons/questions/`, {
        params:data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postGamesQues = (token, data) => {
    return axios.post(`${api}api/lessons/questions/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const postGamesQuesUpload = (token, data) => {
    return axios.post(`${api}api/lessons/questions/file-upload/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
export const patchGamesQues = (token, data, id) => {
    return axios.patch(`${api}api/lessons/questions/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteGamesQues = (token, id) => {
    return axios.delete(`${api}api/lessons/questions/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getDocsCategory = (token) => {
    return axios.get(`${api}api/docs-categories/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postDocsCategory = (token, data) => {
    return axios.post(`${api}api/docs-categories/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchDocsCategory = (token, data, id) => {
    return axios.patch(`${api}api/docs-categories/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteDocsCategory = (token, id) => {
    return axios.delete(`${api}api/docs-categories/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getKlass = (token) => {
    return axios.get(`${api}api/klasses/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postKlass = (token, data) => {
    return axios.post(`${api}api/klasses/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchKlass = (token, data, id) => {
    return axios.patch(`${api}api/klasses/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteKlass = (token, id) => {
    return axios.delete(`${api}api/klasses/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getQuestion = (token) => {
    return axios.get(`${api}api/questions/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getQuestionId = (token, id) => {
    return axios.get(`${api}api/questions/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postQuestion = (token, data) => {
    return axios.post(`${api}api/questions/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchQuestion = (token, data, id) => {
    return axios.patch(`${api}api/questions/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteQuestion = (token, id) => {
    return axios.delete(`${api}api/questions/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getAnswer = (token) => {
    return axios.get(`${api}api/answers/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postAnswer = (token, data) => {
    return axios.post(`${api}api/answers/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchAnswer = (token, data, id) => {
    return axios.patch(`${api}api/answers/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteAnswer = (token, id) => {
    return axios.delete(`${api}api/answers/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getTest = (token) => {
    return axios.get(`${api}api/tests/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postTest = (token, data) => {
    return axios.post(`${api}api/tests/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchTest = (token, data, id) => {
    return axios.patch(`${api}api/tests/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteTest = (token, id) => {
    return axios.delete(`${api}api/tests/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getStudent = (token) => {
    return axios.get(`${api}api/students/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const postStudent = (token, data) => {
    return axios.post(`${api}api/students/create/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const patchStudent = (token, data, id) => {
    return axios.patch(`${api}api/students/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const deleteStudent = (token, id) => {
    return axios.delete(`${api}api/students/${id}/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getRegion = (token) => {
    return axios.get(`${api}api/regions/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getResults = (token) => {
    return axios.get(`${api}api/test-results/`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
