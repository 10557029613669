import { Button, Col, Form, Row, Select, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { deleteAudio, getAudio, getKlassesLessons, getLessons, patchAudio, postAudio } from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
// import { FaPlay } from "react-icons/fa";
import { message } from "antd";
import { useStore } from "../../store/Store";
import { InputFile, InputText } from "../../utils/Inputs";
import { InputSelect } from "../../utils/Inputs";

export default function Audio() {
    const [form] = Form.useForm();
    const [form_sort] = Form.useForm();
    const [data, setData] = useState(null);
    const [edit, setedit] = useState(null);
    const [lessons, setLessons] = useState(null);
    const [changePage, setchangePage] = useState(false);
    const [cookie] = useCookies();
    
  const [lessons_id, setLessons_id] = useState();
    const [klass, setKlass] = useState([]);
  const [selectlesson, setSelectlesson] = useState();
    const [disabledFile, setDisabledFile] = useState(false);
    const [disabledLink, setDisabledLink] = useState(false);
    const [defaultFile, setDefaultFile] = useState([]);

    const audioRef = useRef();

    const setLoader = useStore((state) => state.setLoader);

    const editData = (res) => {
        if (res.file) {
            let file = res.file;
            setDefaultFile([
                {
                    uid: 1,
                    status: "done",
                    url: file,
                    name: file.slice(file.lastIndexOf("/") + 1),
                },
            ]);
            res.file = {
                fileList: [
                    {
                        uid: 1,
                        status: "done",
                        url: file,
                        name: file.slice(file.lastIndexOf("/") + 1),
                    },
                ],
            };
            setDisabledLink(true);
        } else {
            setDisabledFile(true);
        }
        form.setFieldsValue(res);
        setchangePage(true);
        setedit(res.id);
    };

    const showModal = () => {
        setchangePage(true);
    };

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "Nom",
            key: "title",
            dataIndex: "title",
        },
        {
            title: "Ovozli xabar",
            key: "file",
            dataIndex: "id",
            width: "332px",
            render: (id, res) => {
                return (
                    <div
                        style={{
                            maxWidth: "300px",
                        }}
                    >
                        {res.file ? (
                            <div className="table_btns">
                                {/* <Button
                                    type="primary"
                                    onClick={() => {
                                        audioRef.current.src = res.file;
                                        audioRef.current.play();
                                    }}
                                    style={{
                                        borderRadius: "50%",
                                        height: 32,
                                        width: 32,
                                    }}
                                >
                                    <FaPlay
                                        size="1em"
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                            bottom: "0",
                                            left: "0",
                                            margin: "auto auto",
                                        }}
                                    />
                                </Button> */}
                                <audio
                                    id="audio_controls"
                                    ref={audioRef}
                                    controls
                                    src={res.file}
                                    style={{
                                        display: "inline-block",
                                        minWidth: "200px",
                                        width: "100%",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                ></audio>
                            </div>
                        ) : (
                            <a
                                href={res.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    textDecoration: "none",
                                    textAlign: "center",
                                    display: "inline-block",
                                    width: "100%",
                                }}
                            >
                                {res.link && "Havolaga o'tish"}
                            </a>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Mavzu",
            key: "lesson_name",
            dataIndex: "lesson",
            render: (res) => {
                const lesson = lessons.find((x) => x.id === res);
                return lesson ? lesson.title : "-";
              },
        },
        {
            title: "O'zgartirish",
            key: "edit",
            dataIndex: "id",
            render: (res, objc) => {
                return (
                    <Button
                        onClick={() => {
                            editData(objc);
                        }}
                        type="primary"
                    >
                        <TbEdit size={"1.3em"} />
                    </Button>
                );
            },
        },
        {
            title: "O'chirish",
            key: "delete",
            dataIndex: "id",
            render: (res) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            deleteData(res);
                        }}
                        danger
                    >
                        <MdDelete size={"1.3em"} />
                    </Button>
                );
            },
        },
    ];

    const getData = () => {
        getAudio(cookie.token).then((res) => {
            let data = res.data;
            
            setData(data);
            setLoader(false);
        });
    };

    const deleteData = (id) => {
        setLoader(true);
        deleteAudio(cookie.token, id)
            .then((res) => {
                message.success("Ma'lumot o'chirildi");
                getData();
            })
            .catch((err) => {
                setLoader(false);
                message.error("Ma'lumot o'chirilmadi");
            });
    };

    useEffect(() => {
        getKlassData();
        getLessons(cookie.token).then((res) => {
            setLessons(res.data);
            getData();
        });
    }, []);
    const getKlassData = () => {
        getKlassesLessons(cookie.token).then((res) => {
          setKlass(res.data);
        });
      };
    const onFinish = (event) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("title", event.title);
        if (event.link && !disabledLink) {
            formData.append("link", event.link);
        } else {
            formData.append("link", "");
        }
        formData.append("lesson", event.lesson);
        if (event.file && event.file.fileList && event.file.fileList !== 0 && !disabledFile) {
            if (event.file.fileList[0].originFileObj) {
                formData.append("file", event.file.fileList[0].originFileObj);
            }
        } else {
            formData.append("file", "");
        }
        if (edit === null) {
            postAudio(cookie.token, formData)
                .then((res) => {
                    message.success("Ma'lumot saqlandi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot saqlanmadi");
                });
        } else {
            patchAudio(cookie.token, formData, edit)
                .then((res) => {
                    message.success("Ma'lumot o'zgartirildi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot o'zgartirilmadi");
                });
        }
    };

    const handleClose = () => {
        setchangePage(false);
        setedit(null);
        setDisabledFile(false);
        setDisabledLink(false);
        setDefaultFile([]);
        getData();
        form.resetFields();
    };

    const handleChange = (info) => {
        if (info.file.status === "done") {
            setDefaultFile(info.fileList);
            setDisabledLink(true);
        } else {
            setDefaultFile(info.fileList);
            setDisabledLink(false);
        }
    };
    const sortKlass = (e) =>{
  
        let newArray = lessons.filter(c=> c.klass === e)
        setSelectlesson(newArray)
        setLessons_id(null)
        form.setFieldsValue({lesson:null})
        form_sort.setFieldsValue({lesson:null})
      }
      const sortLesson = (e) =>{
        setLessons_id(e)
        getData(e)
      }
    return (
        <div>
            <div className="adminBox">
                <audio ref={audioRef} style={{ display: "none" }}></audio>
                {!changePage ? (
                    <>
                        <div className="admin_btns">
                            <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                                Ovozli xabar qo'shish
                            </Button>
                            <Form
              className="form_sort"
              style={{display:'flex', height:'100%', alignItems:'center', paddingTop:'22px'}}
          form={form_sort}
          name="basic"
          labelCol={{ span: 12 }}
          wrapperCol={{ span:12 }}
          initialValues={{ remember: true }}
          
          autoComplete="off"
        >
          <Form.Item name="klass" required={false}>
              <Select style={{ width: 150, marginLeft: "20px" }} placeholder={"Sinf tanlash"} onChange={sortKlass}>
                {klass.length !== 0 &&
                  klass.map((item, key) => (
                    <Select.Option key={key} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
              </Form.Item>
              <Form.Item name="lesson" required={false}>
              <Select style={{ width: 150, marginLeft: "20px" }} placeholder={"Mavzu tanlash"} onChange={sortLesson}>
                {selectlesson &&
                  selectlesson.map((item, key) => (
                    <Select.Option key={key} value={item.id}>
                      {item.title}
                    </Select.Option>
                  ))}
              </Select>
              </Form.Item>
              </Form>
                        </div>
                        <Table rowKey={(res) => res.id} dataSource={data} columns={columns} />
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFieldsChange={(e, allE) => {
                                if (allE[2].value && allE[2].value.fileList.length !== 0) {
                                    setDisabledLink(true);
                                } else if (allE[3].value && allE[3].value.length !== 0) {
                                    setDisabledFile(true);
                                    form.setFieldValue("file");
                                } else {
                                    setDisabledLink(false);
                                    setDisabledFile(false);
                                }
                            }}
                            autoComplete="off"
                        >
                            <div className="admin_btns">
                                <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
                                    Orqaga
                                </Button>
                                <Button
                                    style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Saqlash
                                </Button>
                            </div>
                            <Row style={{ paddingTop: "20px" }}>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={16} sm={24} xs={24}>
                                    <InputText label="Nom" name="title" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={6} md={8} sm={12} xs={24}>
                                    <InputSelect name="lesson" label="Mavzu" data={lessons} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={6} md={6} sm={12} xs={24}>
                                    <InputFile
                                        label="Ovozli xabar"
                                        name="file"
                                        type="audio"
                                        required={!disabledFile}
                                        disabled={disabledFile}
                                        fileList={defaultFile}
                                        handleChange={handleChange}
                                    />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={24} md={18} sm={24} xs={24}>
                                    <InputText
                                        label="Havola"
                                        name="link"
                                        required={!disabledLink}
                                        disabled={disabledLink}
                                    />
                                </Col>
                                <p className="text-danger text-center w-100" style={{ fontStyle: "italic" }}>
                                    Ovozli xabar yoki havolani to'ldirish majburiy!
                                </p>
                            </Row>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
